
import { defineComponent, ref, markRaw } from "vue";
import { TabStrip as KTabStrip, TabStripTab as KTabStripTab } from "@progress/kendo-vue-layout";
import DashboardGrid from "@/components/DashboardGrid.vue";

declare var $: any;

export default defineComponent({
  name: "AgenciesDashboard",
  components: {
    KTabStrip,
    KTabStripTab,
    DashboardGrid,
  },
  setup() {
    const toolbar = ref(null);
    const tabComponent = ref(null);

    const selectedTab = ref(0);

    return {
      toolbar,
      tabComponent,
      selectedTab
    };
  },
  data() {
    return {
      tabs: [
        {
          id: 0,
          title: "Agencies",
          contentString: "./Tabs/AgenciesGeneralTab.vue",
          component: null,
          componentName: "AgenciesGeneralTab",
          gridIds: [
            "#dashboardAgenciesGeneralGrid",
          ],
        },
      ],
    };
  },
  mounted() {
    const _window: any = window;

    const endDate = new Date();
    endDate.setHours(0, 0, 0, 0);
    const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

    endDate.setDate(1);
    endDate.setMonth(endDate.getMonth() + 1);

    const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0));

    const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0));

    $.post("/form//Grid/DashboardDateRangeFilters", {
          StartDate: utcStartDate.toISOString(),
          EndDate: utcEndDate.toISOString(),
        }, (date: any) => {});

    _window.addPrefix = (value: any) => {
      const formatter = new Intl.NumberFormat("en-GB", {
        style: "decimal",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return `£\u00A0${formatter.format(value)}`;
    }

    _window.addPercentPostfix = (value: any) => {
      return `${value}%`;
    }

    this.initializeToolbar();
    this.onSelectedTab({ selected: 0 });
  },
  methods: {
    initializeToolbar() {
      const toolbar = this.toolbar;
      const _window: any = window;
      const _this = this;

      $.get("/form/grid/DashboardToolbarPartial", {}, (html: string) => {
        html = html.replace(/^[^]*?<div/i, "<div");
        html = html.replace(/\/grid\//gi, "/form/$&");
        $(toolbar).html(html);
      });
      _window.onApplyClick = this.onApplyClick;
    },
    async onSelectedTab(e: any) {
      this.selectedTab = e.selected;
      const tab = this.tabs[this.selectedTab];
      
      if (!tab.component) {
        try {
          const module = await import(`./Tabs/${tab.componentName}.vue`);
          tab.component = markRaw(module.default);
        } catch (error) {
          console.error(`Failed to load component: ${tab.componentName}`, error);
        }
      }
    },
    onApplyClick(e: any) {
      const startDate = new Date($("#startDatePicker").data("kendoDatePicker").value());
      const endDate = new Date($("#endDatePicker").data("kendoDatePicker").value());

      endDate.setDate(1);
      endDate.setMonth(endDate.getMonth() + 1);

      const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0));

      const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0));
      
      if (endDate < startDate) {
        alert("End date should be greater than start date");
        return;
      } else {
        const periodicityGrp = $("#Periodicity")[0];
        const dailyOpt = periodicityGrp.children.daily;
        const monthlyOpt = periodicityGrp.children.monthly;
        const periodicity = $(dailyOpt).hasClass("k-state-active")
          ? $(dailyOpt).attr("value")
          : $(monthlyOpt).attr("value");

        $.post("/form//Grid/DashboardDateRangeFilters", {
          StartDate: utcStartDate.toISOString(),
          EndDate: utcEndDate.toISOString(),
          Periodicity: periodicity
        }, (data: any) => {
          if (data.success) {

            this.tabs.find(tab => tab.id === this.selectedTab)?.gridIds.forEach(gridSelector => {
              const grid = $(gridSelector).data("kendoGrid");
              grid.dataSource.read();
            });
          }
        });

      }
    }
  },
});
