import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Customers from "@/views/Customers.vue";
import Customer from "@/views/Customer.vue";
import Orders from "@/views/Orders.vue";
import Order from "@/views/Order.vue";
import OrderItems from "@/views/OrderItems.vue";

import ShopifyTemplate from "@/views/ShopifyTemplate.vue";
import ReportGenerator from "@/views/ReportGenerator.vue";
import CreateOrder from "@/views/SpecialOrder/CreateOrder.vue";
import EditOrder from "@/views/SpecialOrder/EditOrder.vue";
import DeliveryTest from "@/views/DeliveryTest.vue";

// DeliveryConfig
import DeliveryConfig from "@/views/SuperAdminManagment/DeliveryConfig/_DeliveryConfig.vue";
import DeliveryDimensions from "@/views/SuperAdminManagment/DeliveryConfig/DeliveryDimensions.vue";
import DeliveryDimensionZones from "@/views/SuperAdminManagment/DeliveryConfig/DeliveryDimensionZones.vue";
import DeliveryOptions from "@/views/SuperAdminManagment/DeliveryConfig/DeliveryOptions.vue";
import DeliveryPriceLists from "@/views/SuperAdminManagment/DeliveryConfig/DeliveryPriceLists.vue";
import DeliveryPrices from "@/views/SuperAdminManagment/DeliveryConfig/DeliveryPrices.vue";
import DeliveryTimeRanges from "@/views/SuperAdminManagment/DeliveryConfig/DeliveryTimeRanges.vue";
import DeliveryZoneCountries from "@/views/SuperAdminManagment/DeliveryConfig/DeliveryZoneCountries.vue";
import DeliveryZonePostcodes from "@/views/SuperAdminManagment/DeliveryConfig/DeliveryZonePostcodes.vue";
import DeliveryZones from "@/views/SuperAdminManagment/DeliveryConfig/DeliveryZones.vue";

//GoogleDoc
import GoogleDoc from "@/views/GoogleDoc.vue";

// View Builder
import GridFiltersList from "@/views/ViewBuilder/GridFiltersList.vue";
import GridFiltersDetails from "@/views/ViewBuilder/GridFilterDetails.vue";
import SpecialReportList from "@/views/SpecialReport/SpecialReportList.vue";

//Admin management
import CnShowcase from "@/views/SuperAdminManagment/ShowCase/CnShowcase.vue";
import CnShowcasePost from "@/views/SuperAdminManagment/ShowCase/CnShowcasePost.vue";

// Analytics
import Analytics from "@/views/SuperAdminManagment/Analytics/_Analytics.vue";
import NewSalesCohorts from "@/views/SuperAdminManagment/Analytics/NewSalesCohorts.vue";

import RefundRedoTypes from "@/views/SuperAdminManagment/RefundRedoTypes/RefundRedoTypes.vue";
import ChAdminManagement from "@/views/SuperAdminManagment/ChAdminManagement/ChAdminManagement.vue";
import GridFilterCategories from "@/views/SuperAdminManagment/GridFilterCategories/GridFilterCategories.vue";
import ForInvoicingManagment from "@/views/SuperAdminManagment/ForInvoicingManagment/ForInvoicingManagment.vue";
import AverageSpendCategoryManagment from "@/views/SuperAdminManagment/AverageSpendCategoryManagment/AverageSpendCategoryManagment.vue";

import PromoCodes from "@/views/SuperAdminManagment/promoCodes/promoCodes.vue";
import PromoCode from "@/views/SuperAdminManagment/promoCodes/promoCode.vue";
import BulkPromoCodes from "@/views/SuperAdminManagment/promoCodes/bulkPromoCodes.vue";

import PrintingRules from "@/views/SuperAdminManagment/printingRules/_printingRules.vue";
import USPrintRules from "@/views/SuperAdminManagment/printingRules/usPrintRules.vue";
import USPrintRule from "@/views/SuperAdminManagment/printingRules/usPrintRule.vue";
import PrintRoute from "@/views/SuperAdminManagment/printingRules/printRoute.vue";
import PrintRoutes from "@/views/SuperAdminManagment/printingRules/printRoutes.vue";

import OrderStateManager from "@/views/SuperAdminManagment/orderStateManager/orderStateManager.vue";
import OrderStateVersions from "@/views/SuperAdminManagment/orderStateManager/orderStateVersions.vue";

// Customer
import ProductVariants from "@/views/ProductVariants.vue";

// Commissionable Revenues
import SalesRepCommissions from "@/views/SuperAdminManagment/Commissions/SalesRepCommissions.vue";
import AccountHandlerCommissions from "@/views/SuperAdminManagment/Commissions/AccountHandlerCommissions.vue";
import Dashboard from "@/views/Dashboard/OrdersDashboard/Dashboard.vue";
import DashboardSettings from "@/views/DashboardSettings.vue";
import ProductionTeams from "@/views/ProductionTeams.vue";
import ProductionTeamAdmin from "@/views/ProductionTeamAdmin.vue";
import ProductivityDashboard from "@/views/Dashboard/ProductivityDashboard/ProductivityDashboard.vue";
import ProductivityDashboardAdjustments from "@/views/PostCalculationAdjustment/ProductivityDashboardAdjustments.vue";

import ShopifyOrdersRecovery from "@/views/ShopifyOrders/ShopifyOrdersRecovery.vue";
import AgenciesDashboard from "@/views/Dashboard/AgenciesDashboard/AgenciesDashboard.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/production-teams",
    name: "ProductionTeams",
    component: ProductionTeams,
  },
  {
    path: "/production-team-admin",
    name: "ProductionTeamAdmin",
    component: ProductionTeamAdmin,
  },
  {
    path: "/adjustments",
    name: "Adjustments",
    component: ProductivityDashboardAdjustments,
  },
  {
    path: "/order-productivity-dashboard",
    name: "OrderProductivityDashboard",
    component: ProductivityDashboard,
  },
  {
    path: "/agencies-dashboard",
    name: "AgenciesDashboard",
    component: AgenciesDashboard,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/dashboard-settings",
    name: "DashboardSettings",
    component: DashboardSettings,
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
  },
  {
    path: "/orders/:orderId",
    name: "Order",
    component: Order,
    props: true,
  },
  {
    path: "/orders-items",
    name: "OrderItems",
    component: OrderItems,
  },
  {
    path: "/new-order/:customerId",
    name: "New Order",
    component: CreateOrder,
  },
  {
    path: "/edit-order/:orderId",
    name: "Edit Order",
    component: EditOrder,
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
  },
  {
    path: "/customers/:customerId",
    name: "Customer",
    component: Customer,
  },
  {
    path: "/customers/:customerId/products/:productId/variants",
    name: "Product Variants",
    component: ProductVariants,
  },
  {
    path: "/report-generator",
    name: "OrderReportGenerator",
    component: ReportGenerator,
  },
  {
    path: "/delivery/test",
    name: "DeliveryTest",
    component: DeliveryTest,
  },
  {
    path: "/shopify/template",
    name: "ShopifyTemplate",
    component: ShopifyTemplate,
  },
  {
    path: "/shopify/orders-recovery",
    name: "ShopifyOrdersRecovery",
    component: ShopifyOrdersRecovery,
  },
  // Analytics
  {
    path: "/analytics",
    name: "Analytics",
    component: Analytics,
    children: [
      {
        path: "new-sales-cohorts",
        name: "NewSalesCohorts",
        component: NewSalesCohorts,
      },
    ],
  },
  // DeliveryConfig
  {
    path: "/delivery/config",
    name: "DeliveryConfig",
    component: DeliveryConfig,
    redirect: {
      name: "DeliveryOptions",
    },
    children: [
      {
        path: "delivery-options",
        name: "DeliveryOptions",
        component: DeliveryOptions,
      },
      {
        path: "delivery-time-ranges",
        name: "DeliveryTimeRanges",
        component: DeliveryTimeRanges,
      },
      {
        path: "delivery-zones",
        name: "DeliveryZones",
        component: DeliveryZones,
      },
      {
        path: "delivery-zone-countries",
        name: "DeliveryZonesCountries",
        component: DeliveryZoneCountries,
      },
      {
        path: "delivery-dimensions",
        name: "DeliveryDimensions",
        component: DeliveryDimensions,
      },
      {
        path: "delivery-dimension-zones",
        name: "DeliveryDimensionZones",
        component: DeliveryDimensionZones,
      },
      {
        path: "delivery-prices",
        name: "DeliveryPrices",
        component: DeliveryPrices,
      },
      {
        path: "delivery-price-lists",
        name: "DeliveryPriceLists",
        component: DeliveryPriceLists,
      },
      {
        path: "delivery-zone-postcodes",
        name: "DeliveryZonePostcodes",
        component: DeliveryZonePostcodes,
      },
    ],
  },
  {
    path: "/cn-showcase",
    name: "CnShowcase",
    component: CnShowcase,
  },
  {
    path: "/refund-types",
    name: "RefundRedoTypes",
    component: RefundRedoTypes,
  },
  {
    path: "/ch-admin-management",
    name: "ChAdminManagement",
    component: ChAdminManagement,
  },
  {
    path: "/google-doc",
    name: "GoogleDoc",
    component: GoogleDoc,
  },
  {
    path: "/cn-showcase/posts/:postId?",
    name: "CnShowcasePost",
    component: CnShowcasePost,
  },
  {
    path: "/grid-filters-list",
    name: "GridFilterList",
    component: GridFiltersList,
  },
  {
    path: "/grid-filters/filter/:gridFilterId?",
    name: "GridFilterDetails",
    component: GridFiltersDetails,
  },
  {
    path: "/special-report-list",
    name: "SpecialReportList",
    component: SpecialReportList,
  },
  {
    path: "/grid-filter-categories",
    name: "GridFilterCategories",
    component: GridFilterCategories,
  },
  {
    path: "/for-invoicing-managment",
    name: "ForInvoicingManagment",
    component: ForInvoicingManagment,
  },
  {
    path: "/average-spend-category-managment",
    name: "AverageSpendCategoryManagment",
    component: AverageSpendCategoryManagment,
  },
  {
    path: "/promotional-codes",
    name: "PromotionalCodes",
    component: PromoCodes,
  },
  {
    path: "/promotional-code/:id",
    name: "PromotionalCode",
    component: PromoCode,
  },
  {
    path: "/promotional-code-new",
    name: "PromotionalCodeNew",
    component: PromoCode,
  },
  {
    path: "/bulk-promotional-code",
    name: "BulkPromotionalCode",
    component: BulkPromoCodes,
  },
  {
    path: "/order-state-manager/:id",
    name: "StateManager",
    component: OrderStateManager,
  },
  {
    path: "/order-state-manager",
    name: "StateManagerVersions",
    component: OrderStateVersions,
  },
  {
    path: "/printing-rules",
    name: "PrintingRules",
    component: PrintingRules,
    redirect: {
      name: "USPrintRules",
    },
    children: [
      {
        path: "/printing-rules/us-print-rules",
        name: "USPrintRules",
        component: USPrintRules,
      },
      {
        path: "/printing-rules/us-print-rule/:id",
        name: "USPrintRule",
        component: USPrintRule,
      },
      {
        path: "/printing-rules/us-print-rule-new",
        name: "USPrintRuleNew",
        component: USPrintRule,
      },
      {
        path: "/printing-rules/print-routes",
        name: "PrintRoutes",
        component: PrintRoutes,
      },
      {
        path: "/printing-rules/print-route/:id",
        name: "PrintRoute",
        component: PrintRoute,
      },
      {
        path: "/printing-rules/print-route-new",
        name: "PrintRouteNew",
        component: PrintRoute,
      },
    ],
  },
  {
    path: "/commissions/sales-reps",
    name: "SalesRepsCommissions",
    component: SalesRepCommissions,
  },
  {
    path: "/commissions/account-handlers",
    name: "AccountHandlerCommissions",
    component: AccountHandlerCommissions,
  },
];

const router = createRouter({
  history: createWebHistory("/"), //process.env.BASE_URL
  routes,
});

export default router;
