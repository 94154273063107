
import { defineComponent, Ref, ref } from "vue";

declare var $: any;
const _window: any = window;

export default defineComponent({
  name: "ForInvoicingManagement",
  setup() {
    const grid: Ref<any> = ref(null);
    return { grid };
  },
  mounted() {
    const grid = this.grid;

    _window.Flag_Change = (event: any) => {
      console.log(event)
      if (event?.action === "itemchange") {
        if (event.field == "OrderStatus") {
          const ddl = $("#OrderStatus").data("kendoDropDownList");
          if (ddl?.selectedIndex != 0) {
            const ddlData = ddl.dataSource.data();
            event.items[0].OrderStoppingStatus =
              ddlData[ddl.selectedIndex - 1].OrderStateValue;
          }
        } else {
          if (event.items[0].IsAgency) {
            if (event.field == "_TouchRating") {
              const ddl = $("#_TouchRating").data("kendoDropDownList");
              if (ddl?.selectedIndex != 0) {
                const ddlData = ddl.dataSource.data();
                event.items[0].AgencyTouchRating =
                  ddlData[ddl.selectedIndex - 1].Value;
              }
            } else if (event.field == "_SalesTrajectory") {
              const ddl = $("#_SalesTrajectory").data("kendoDropDownList");
              if (ddl?.selectedIndex != 0) {
                const ddlData = ddl.dataSource.data();
                event.items[0].AgencySalesTrajectory =
                  ddlData[ddl.selectedIndex - 1].Value;
              }
            }
          }
        }
      }
    };

    $.get("/form/grid/ForInvoicingManagmentPartial", {}, (html: string) => {
      html = html.replace(/^[^]*?<div/i, "<div"); // remove kendo trial notice
      html = html.replace(/\/grid\//gi, "/form/$&"); // fix up server url slug
      $(grid).html(html);
    });
  },
});
